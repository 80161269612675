<template>
  <v-dialog no-click-animation v-if="$attrs.value" transition="dialog-bottom-transition" v-bind="$attrs"
    v-on="$listeners">
    <slot />
  </v-dialog>
</template>

<script>
export default {
  name: "FullscreenDialog",
  watch: {
    "$attrs.value": function () {
      const html = document.querySelector("html");
      if (this.$attrs.value) html.classList.add("remove-scrollbar");
      else html.classList.remove("remove-scrollbar");
    },
  },
};
</script>

<style>
.remove-scrollbar {
  overflow: hidden;
}
</style>

