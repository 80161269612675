<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table :items="tickets" :search="search" :headers="headers" :loading="isLoading('get:tickets')">
          <template v-slot:top>
            <v-row class="mt-2">
              <v-col cols="12">
                <v-row>
                  <v-col cols="5">
                    <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify"
                      clearable />
                  </v-col>
                  <v-col class="text-end">
                    <v-btn class="mr-4 elevation-0" text @click="getTickets">
                      <v-icon left>
                        mdi-reload
                      </v-icon>
                      atualizar
                    </v-btn>
                    <v-btn color="success" class="elevation-0" @click="() => dialog.create = true">
                      <v-icon left>
                        mdi-plus
                      </v-icon>
                      Novo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            {{ String(item.id).padStart(4, '0') }}
          </template>
          <template v-slot:[`item.messages`]="{ item }">
            <v-btn small depressed text color="primary" @click="() => handleOpenMessages(item.id)">ver
              conversa</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <FullscreenDialog v-model="dialog.create" maxWidth="750px" scrollable>
      <v-card>
        <v-card-title class="sticky-title title-border">
          Novo Ticket
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="handleCreateTicket">
            <v-autocomplete outlined label="Categoria" v-model="newTicket.category" dense :items="categories"
              item-value="id" item-text="nome" />
            <v-textarea placeholder="Escreva sua mensagem..." outlined label="Mensagem" v-model="newTicket.message"
              dense />
            <div class="d-flex justify-end">
              <ConfirmButton color="success" :loading="isLoading('post:ticket')">
                Salvar
              </ConfirmButton>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.messages" maxWidth="750px" scrollable>
      <v-card>
        <v-card-title class="sticky-title title-border">
          Ticket #{{ String(selectedTicket).padStart(4, '0') }}
        </v-card-title>
        <v-card-text ref="chatBodyRef">
          <div v-for="message in messages" v-bind:key="message.id" class="message-container my-4"
            :class="{ 'user-message': message.usuario.id === userData.id }">
            <v-card class="px-2 py-4 message" outlined>
              {{ message.message }}
            </v-card>
            <footer class="message-footer">
              <span>
                {{ message.createdAtFormatted }}
              </span>
            </footer>
          </div>
        </v-card-text>
        <v-card-actions style="padding-bottom: 15px">
          <v-form style="width: 100%" @submit.prevent="handleSendMessage">
            <v-text-field placeholder="Escreva sua mensagem..." hide-details outlined v-model="newMessage" dense
              append-icon="mdi-send" @click:append="handleSendMessage">
            </v-text-field>
          </v-form>
        </v-card-actions>
      </v-card>
    </FullscreenDialog>
  </v-container>
</template>
<script>
import api from '../../http'
import moment from 'moment'
import UtilsFunc from '../../service/utilsFunc';
import FullscreenDialog from "../FullscreenDialog.vue";
import { mapGetters } from 'vuex';
import ConfirmButton from '../ConfirmButton.vue'

const { withCRUDUtils } = UtilsFunc

const websocketProtocol = window.location.protocol === "https:" ? "wss" : "ws";
const urlWithouProtocol = process.env.VUE_APP_API_URL.replace(/http(?:s)?:\/{2}/i, "")

const wsEndpoint = roomId => `${websocketProtocol}://${urlWithouProtocol}/ws/chats/${roomId}/`

export default {
  data: () => withCRUDUtils({
    tickets: [],
    headers: [
      { text: "Ticket", value: "id" },
      { text: "Status", value: "ticket_status.nome" },
      { text: "Categoria", value: "categoria.nome" },
      { text: "Aberto em", value: "createdAtFormatted" },
      { text: "Conversa", value: "messages", width: 50, align: "center" },
    ],
    messages: [],
    categories: [],
    socket: null,
    newMessage: "",
    newTicket: {},
    selectedTicket: null,
    dialog: {
      messages: false
    },
    search: "",
    websocketData: [],
    message: "", // Adicione um campo para armazenar a mensagem a ser enviada
    ticketStatus: []
  }),
  computed: {
    ...mapGetters(['userData']),
  },
  methods: {
    async handleOpenMessages(ticket) {
      this.dialog.messages = true
      this.selectedTicket = ticket


      await this.getMessages(ticket)
      this.socket = this.createWSConnection()
    },
    async getTickets() {
      this.setLoading("get:tickets")
      try {
        const { data } = await api.get(`/chats/tickets/?usuario=${this.userData.id}`);
        const formatTicket = (ticket) => ({
          ...ticket,
          createdAtFormatted: moment(ticket.created_at).format("DD/MM/YY HH:mm"),
        })

        this.tickets = data
          .map(formatTicket)
      } catch (error) {
        console.log(error)
      } finally {
        this.setLoading("get:tickets", true)
      }
    },
    async getCategories() {
      try {
        const { data } = await api.get("/chats/categorias/");
        this.categories = data
      } catch (error) {
        console.log(error)
      }
    },
    async getTicketStatus() {
      try {
        const { data } = await api.get("/chats/ticket-status/");
        this.ticketStatus = data
      } catch (error) {
        console.log(error)
      }
    },
    createWSConnection() {
      const endpoint = wsEndpoint(this.selectedTicket)
      const socket = new WebSocket(endpoint);

      socket.addEventListener('message', (event) => {
        const messageData = JSON.parse(event.data)

        this.messages.push({
          usuario: messageData.usuario, message: messageData.message, id: String(Date.now()),
          createdAtFormatted: moment().format("DD/MM/YY HH:mm"),
        })
        this.newMessage = ""

        this.scrollToEndOfChat()
      });

      socket.addEventListener('close', (event) => {
        console.error('WebSocket closed:', event);
      });

      socket.addEventListener('error', (event) => {
        if (event.type === 'error') {
          console.error('WebSocket error:', event);
          this.$toast.error('Falha ao conectar no chat')
        }
      });

      return socket; // Guarde o objeto WebSocket para referência posterior
    },
    async handleCreateTicket() {
      const LOADING_NAME = 'post:tickets'
      this.setLoading(LOADING_NAME)
      try {
        const fields = {
          usuario: this.userData.id,
          categoria: this.newTicket.category,
          ticket_status: 1
        }

        const { data } =
          await api.post('/chats/ticket-post/', fields)

        this.dialog.create = false

        this.selectedTicket = data.id
        this.newMessage = this.newTicket.message
        await this.handleOpenMessages(data.id)
        await this.getTickets()
        this.handleSendMessage()
      } catch (error) {
        console.log(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    handleSendMessage() {
      if (this.newMessage.trim() === "") return
      const socketMessage = {
        msg_type: 'chat.message',
        room: this.userData.id,
        user: this.userData.id,
        ticket: this.selectedTicket,
        admin: null,
        message: this.newMessage,
      }
      this.socket.send(JSON.stringify(socketMessage));
      this.message = ""; // Limpe o campo de entrada após enviar a mensagem
    },
    async getMessages(ticket) {
      try {
        const { data } = await api.get(`/chats/messages/?ticket=${ticket}`);
        const formatTicket = (ticket) => ({
          ...ticket,
          createdAtFormatted: moment(ticket.created_at).format("DD/MM/YY HH:mm"),
        })

        this.messages = data.map(formatTicket)
      } catch (error) {
        console.log(error)
      }
    },
    scrollToEndOfChat() {
      setTimeout(() => {
        const ref = this.$refs.chatBodyRef
        if (ref)
          ref.scroll({ top: 9999 })
      }, 50)
    }
  },
  watch: {
    "userData": function () {
      if (this.userData.id) this.getTickets()
    },
    "dialog.messages": function () {
      if (this.dialog.messages) {
        this.scrollToEndOfChat()
      } else {
        if (this.socket) this.socket.close()
      }
    }
  },
  mounted() {
    if (this.userData.id) this.getTickets()
    this.getCategories()
  },
  components: {
    FullscreenDialog,
    ConfirmButton
  }
};
</script>

<style scoped>
* {
  --user-message-color: #cef0cf;
  --admin-message-color: #b2d7f4;
}

.message-container {
  max-width: 250px;
  position: relative;
}

.message {
  background-color: var(--user-message-color);
}

.message-container:not(.user-message) .message {
  background-color: var(--admin-message-color);
}

.message-tail-container {
  width: 25px;
  height: 25px;
  position: absolute;
  overflow: hidden;
  bottom: -15px;
  right: -1px;
}

.user-message {
  margin-left: auto;
}

.message-footer {
  font-size: 0.8rem;
}
</style>
